@import "src/variables.module.scss";

.label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: $primary-text-color;
  font-weight: 500;
}
