@import "src/variables.module.scss";

.textContainer {
  padding: 1em;

  .title {
    // line-height: 1.4rem;
    line-height: 1.5rem;
    color: $primary-text-color;
    font-weight: bold;
    font-size: 1.29rem;
    // margin-top: -0.5em;
  }

  .description {
    color: $secondary-text-color;
    margin-top: 0.5em;
    font-size: 0.9rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.socialProofContainer {
  border-left: 1.5px solid $outline-color;
  padding-left: 1em;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 1.4em;

  .peopleNumber {
    margin-top: -0.5em;
    color: $secondary-text-color;
    font-size: 0.9rem;

    // span {
    //   font-weight: bold;
    // }
  }

  .employeesImages {
    display: inline-flex;
    align-items: center;

    img {
      width: 2rem;
      border-radius: 9999px;
      border: 2px solid white;
    }

    img:nth-child(n + 2) {
      margin-left: calc(-0.7rem * 1);
    }
  }

  .rating {
    margin-left: 0.5em;
    .stars {
      display: inline-flex;
      align-items: center;

      svg {
        width: 1rem;
        height: 1rem;
        fill: gold;
      }
    }
  }
}

// .productName {
//   color: $primary-text-color;
//   font-weight: 500;
//   //   font-size: 1.5rem;
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 2; /* number of lines to show */
//   line-clamp: 2;
//   -webkit-box-orient: vertical;

//   &:hover {
//     cursor: pointer;
//     text-decoration: none;
//     color: #0071e3;
//   }
// }

.productInfo {
  // display: inline-flex;
  // align-items: center;

  .productImage {
    width: 100%;
    // max-height: 20em;
    height: 11em;
    object-fit: cover;
    cursor: pointer;
    border-bottom: 1px solid $outline-color;
  }
}
