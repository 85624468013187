@import "src/variables.module.scss";

$payment-method-border-radius: 0.7em;

.paymentMethods {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .paymentMethodItem {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 0.5em;
    border: 1px solid $outline-color;
    border-radius: $payment-method-border-radius;
    height: 3.5em;

    .leftContainer {
      display: inline-flex;
      align-items: center;
      gap: 0.5em;

      .radioInput {
        // accent-color: $primary-color;
      }

      p {
        font-weight: 600;
        color: $primary-text-color;
      }
    }

    img {
      height: 80%;
      margin-right: 0.5em;
    }
  }
  .isSelected {
    background-color: $background-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    // border: 2px solid $primary-color;
  }

  .paymentOptionsContainer {
    padding: 0.5em;
    background-color: $background-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border: 1px solid $outline-color;

    .redirectText {
      color: $secondary-text-color;
    }
  }
}

.paymentMethodErrorContainer {
  display: inline-flex;
  align-items: center;

  .paymentMethodErrorMessage {
    color: $error-color;
    font-size: 1em;
  }

  .errorIcon {
    height: 1.5em;
    color: $error-color;
    margin-right: 0.625em;
  }
}
