@import "src/variables.module.scss";

.loadingView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  svg {
    fill: $primary-color;
  }
}
