@import "src/variables.module.scss";

.paymentFinishedView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner {
    max-width: 30.8em;

    .title {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
      color: $primary-text-color;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
    }
    .description {
      // margin-top: 0.5em;
      color: $primary-text-color;
      font-size: 1rem;
      text-align: center;

      a {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
      }
    }

    .subTitle {
      font-weight: bold;
      color: $primary-text-color;
      text-align: left;
      font-size: 1.1rem;
      margin-top: 1.5em;
    }

    .bankTransferInstructions {
      background-color: $container-color;
      border: 1px solid $outline-color;
      border-radius: $border-radius;
      padding: 1em;
      width: 100%;
      box-shadow: 0 var(1px, 1px) 3px 0 hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.1, 0.1)),
        var(--shadow-border, 0 0 0 var(1px, 1px) hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.07, 0.07)));

      .inlineItem {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        margin-bottom: 0.1em;

        label {
          color: $secondary-text-color;
          font-size: 1rem;
        }
        span {
          color: $primary-text-color;
          font-size: 1rem;
        }
      }
    }

    .bottomButtons {
      margin-top: 1em;
      display: inline-flex;
      align-items: center;
      gap: 1em;
    }
  }
}
