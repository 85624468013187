@import "src/variables.module.scss";

.button {
  display: inline-flex;
  align-items: center;
  color: $primary-color;
  cursor: pointer;
  // font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}
