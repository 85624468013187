/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
/* .StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
} */

/* .StripeElement--webkit-autofill { */
/* background-color: red; */
/* background-color: #fefde5 !important; */
/* } */

.StripeElement {
  display: block !important;
  padding: 0.625rem !important;
  background-color: #ffffff !important;
  color: #111827 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  border-width: 1px !important;
  border-color: #f1f1f1 !important;
}

.StripeElement--empty {
  display: block !important;
  padding: 0.625rem !important;
  background-color: #ffffff !important;
  color: #002333 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  border-width: 1px !important;
  border-color: #f1f1f1 !important;
}
.StripeElement--focus {
  border-color: #96bb33 !important;
  --ring-color: #96bb33 !important;
  --tw-ring-opacity: 1 !important;
}
.StripeElement--invalid {
  /* border-color: #fa755a; */
  background-color: #fef2f2 !important;
  /* color: #7f1d1d !important; */
  color: #b91c1c !important;
  border-color: #ef4444 !important;
  --ring-color: #ef4444 !important;
  --tw-ring-opacity: 1 !important;
}
