@import "src/variables.module.scss";

.selectInputContainer {
  label {
    display: block;
    margin-bottom: 0.5em;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    color: $primary-text-color;
    font-weight: 500;
  }

  .outerInput {
    background-color: $container-color;
    border: 1px solid rgb(209 213 219);
    color: $primary-text-color;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    display: block;
    width: 100%;
    border-radius: $border-radius;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    select {
      width: 100%;
      background-color: $container-color;
      border-radius: $border-radius;
      padding: 0.625rem;
      outline: none;
    }
  }
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $container-color inset !important;
}

option:-webkit-autofill,
option:-webkit-autofill:hover,
option:-webkit-autofill:focus,
option:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $container-color inset !important;
}
