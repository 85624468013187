@import "src/variables.module.scss";

.checkoutView {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }

  .gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 65% 33%;
    column-gap: 2%;
    row-gap: 2em;

    .orderDetailsContainer_mobile {
      display: none;
      flex-direction: column;
    }

    .leftContainerGrid {
      // display: grid;
      display: flex;
      flex-direction: column;
      row-gap: 1.5em;
      width: 100%;
    }

    .rightContainerGrid {
      display: flex;
      flex-direction: column;
      position: -webkit-sticky;
      position: sticky;
      top: 2em;
      row-gap: 0.5em;
      width: 100%;
    }
  }
}

.title {
  color: $primary-text-color;
  font-weight: bold;
  font-size: 1.5rem;
}
.description {
  color: $secondary-text-color;

  a {
    color: $primary-color;
    text-decoration: none;
  }
}

.payButtonContainer {
  width: 100%;
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .checkoutView {
    margin-bottom: 5em;
    width: 75%;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .checkoutView {
    width: 90%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .checkoutView {
    margin-bottom: 30em;
    width: 90%;

    .gridContainer {
      display: grid;
      // grid-template-columns: 1fr;

      grid-template-columns: 60% 40%;

      // .leftContainerGrid {
      //   margin-bottom: 2em;
      // }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .checkoutView {
    margin-bottom: 30em;
    width: 75%;

    .gridContainer {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .checkoutView {
    margin-bottom: 40em;
    width: 90%;

    .gridContainer {
      display: grid;
      grid-template-columns: 1fr;

      .orderDetailsContainer_mobile {
        display: flex;
      }

      .orderDetailsContainer {
        display: none;
      }
    }
  }
}
