@import "src/variables.module.scss";

.checkoutPriceSummaryContainer {
  padding: 1em;

  .divider {
    margin: 1em 0;
    height: 1px;
    width: 100%;
    background-color: $divider-color;
  }

  .inlineContainer {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .priceTitle {
      color: $primary-text-color;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .priceValue {
      color: $primary-text-color;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .priceAddidtionText {
      font-weight: 500;
    }

    .priceAddidtionValue {
      font-weight: 500;
      color: $primary-text-color;
    }
  }

  .addDiscountCodeButton {
    display: inline-flex;
    align-items: center;
    color: $primary-color;
    cursor: pointer;
    // font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}
