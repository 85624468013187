@import "src/variables.module.scss";

.defaultInputContainer {
  .errorMessage {
    color: $error-color;
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
    margin-top: 0.25rem; /* 4px */
  }
  .successMessage {
    color: $success-color;
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
    margin-top: 0.25rem; /* 4px */
  }

  label {
    display: block;
    margin-bottom: 0.5em;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    color: $primary-text-color;
    font-weight: 500;
  }

  .input {
    background-color: $container-color;
    border: 1px solid rgb(209 213 219);
    color: $primary-text-color;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    display: block;
    width: 100%;
    border-radius: $border-radius;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      background-color: $container-color;
      border-radius: $border-radius;
      padding: 0.625rem;
      outline: none;
    }

    &:focus-within {
      border-color: $primary-color;
    }

    .errorIcon {
      height: 2em; /* 14px */
      color: $error-color;
      margin-right: 0.625rem;
    }
    .successIcon {
      height: 2em; /* 14px */
      color: $success-color;
      margin-right: 0.625rem;
    }
  }

  .inputError {
    border-color: $error-color;
    background-color: #fef2f2 !important;

    input {
      background-color: inherit !important;
    }

    &:focus-within {
      border-color: $error-color;
    }
  }

  .inputSuccess {
    border-color: $success-color;
    background-color: #f2fef3 !important;

    input {
      background-color: inherit !important;
    }

    &:focus-within {
      border-color: $success-color;
    }
  }

  // input {
  //   // background-color: rgb(249 250 251);
  //   background-color: $container-color;
  //   border: 1px solid rgb(209 213 219);
  //   // border-color: $outline-color;
  //   color: $primary-text-color;
  //   font-size: 0.875rem; /* 14px */
  //   line-height: 1.25rem; /* 20px */
  //   display: block;
  //   padding: 0.625rem;
  //   width: 100%;
  //   border-radius: $border-radius;
  //   // outline-color: rgb(209 213 219);
  //   outline: none;

  //   &:focus {
  //     border-color: $primary-color;
  //   }

  //   // &:-webkit-autofill {
  //   //   -webkit-text-fill-color: $container-color !important;
  //   // }
  // }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $container-color inset !important;
  }
}
