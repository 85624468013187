@import "src/variables.module.scss";

.header {
  background-color: $container-color;
  border: 1px solid $outline-color;
  border-radius: $border-radius;
  width: 100%;
  padding: 1em;
  margin-bottom: 5em;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 var(1px, 1px) 3px 0 hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.1, 0.1)),
    var(--shadow-border, 0 0 0 var(1px, 1px) hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.07, 0.07)));

  img {
    height: 2.3em;
  }

  .cancelButton {
    display: inline-flex;
    align-items: center;
    color: $secondary-text-color;

    .icon {
      margin-right: 0.5em;
      fill: $secondary-text-color;
      height: 1em;
      width: 1em;
    }
  }
}
