@import "src/variables.module.scss";

.productNotFoundView {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  .title {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: $primary-text-color;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
  }

  .description {
    // margin-top: 0.5em;
    color: $primary-text-color;
    font-size: 1rem;
    text-align: center;

    a {
      color: $primary-color;
      text-decoration: none;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
