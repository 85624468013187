@import "src/variables.module.scss";

.container {
  background-color: $container-color;
  border-radius: $border-radius;
  overflow: hidden;
  border: 1px solid $outline-color;
  // padding: 1em;
  width: 100%;

  box-shadow: 0 var(1px, 1px) 3px 0 hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.1, 0.1)),
    var(--shadow-border, 0 0 0 var(1px, 1px) hsla(var(0, 0), var(0%, 0%), var(0%, 0%), var(0.07, 0.07)));
}

.title {
  line-height: 1.4rem;
  color: $primary-text-color;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.5em;
}
