.centerdViewContainer {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .centerdViewContainer {
    width: 75%;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .centerdViewContainer {
    width: 90%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .centerdViewContainer {
    width: 90%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .centerdViewContainer {
    width: 75%;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .centerdViewContainer {
    width: 90%;
  }
}
