@import "src/variables.module.scss";

.footer {
  margin-top: 4em;
  padding: 1em 0;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  font-size: 0.9rem;

  a {
    color: $secondary-text-color;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
