$primary-color: #96bb33; //#1355ff
$primary-color-hover: #97bb33e1;
$primary-color-disabled: #97bb33c2;

$primary-text-color: #002333;
$secondary-text-color: #96a0b5; // #b0b0b0
$outline-color: #f1f1f1;
$divider-color: #ebebeb;
// $icon-color: #9f9f9f;
$background-color: #f6f8ec;
$container-color: #ffffff;

$success-color: #40c057;
$warning-color: #f89406;
$error-color: #fe5050;

$border-radius: 0.8em;
