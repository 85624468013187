@import "src/variables.module.scss";

.primaryButton {
  margin: 0;
  border: 0;
  cursor: pointer;
  text-align: center;
  background: $primary-color;
  color: #fff;
  font-size: 17px;
  line-height: 0;
  // font-weight: 400;
  font-weight: 500;
  letter-spacing: -0.022em;
  min-width: 28px;
  padding: 1.7em 31px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  white-space: normal;
  display: inline-block;
  quotes: "„" "“";

  &:hover {
    background: $primary-color-hover;
    text-decoration: none;
  }
}

.isLoading {
  background-color: $primary-color-disabled;
  cursor: not-allowed;

  &:hover {
    background-color: $primary-color-disabled !important;
  }
}
